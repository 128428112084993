import React, { useState } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import articleData from '../data/articledata';

function DartsLibrary() {
    {/* Search Term Variable */}
    const [searchTerm, setSearchTerm] = useState('');
    {/* Handle Search Term */}
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    {/* Filter Articles by Search Term & Page Category */}
    const filteredArticles = articleData.filter(
    (item) =>
        (item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.summary.toLowerCase().includes(searchTerm.toLowerCase())) &&
        item.category.toLowerCase() === 'darts'
    );
    return (
    <>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
        <div className="library">
            <h1>Darts Article Library</h1>
            <p>Welcome to the Darts Section at Darts Data Centre – Your Comprehensive Guide to the World of Darts! Explore the intricate details of darts, from renowned brands and varying weights to the nuances of grips and beyond.<br/><br/> Whether you're a seasoned player seeking the perfect equipment or a beginner eager to understand the essentials, our Darts Section is your one-stop destination. Uncover insights into different dart brands, discover the ideal weight for your throw, and master the art of finding the perfect grip.</p>
            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search articles..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {/* Iterate Articles by Search Term */}
            <div className="library_articles">
                {filteredArticles.map(item => (
                    <div key={item.id} className="article_card">
                        <h5>{item.title}</h5>
                        <h6>{item.category}</h6>
                        <p>{item.summary}</p>
                        <Link to={item.link}>Read More</Link>
                    </div>
                ))}
            </div>
        </div>
    </> 
    );
}

export default DartsLibrary;