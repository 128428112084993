import React from 'react';
import HeroSwiper from '../components/HeroSwiper';
import Promo from '../components/promo';
import FeaturedArticles from '../components/featuredarticles';
import Affiliates from '../components/affiliates';
import About from '../components/about';
import PastEventsAPIMin from '../components/pastevents_min';
import NextEventsAPIMin from '../components/nextevents_min';

function Home() {
    return (
            <>
                {/* Hero Swiper Component*/}
                <HeroSwiper/>
                {/* Promo Component */}
                <Promo/>
                {/* About DDC Component */}
                <About/>
                {/* Affiliates and Promotions Component
                */}
                <Affiliates/>
                {/* Featured Articles Component */}
                <FeaturedArticles/>
                {/* Past Events Component */}
                <PastEventsAPIMin />
                {/* Past Events Component */}
                <NextEventsAPIMin />
            </>
            );
            }

export default Home;