import React from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";

function Affiliates() {
    return (
    <div className="wrapper_tile">
        <h1>Affiliates | Promotions</h1>
        <div className="col_wrapper">
            <div className="col">
                <p>Your go-to destination for exclusive deals on top dart brands! At Darts Data Centre, we are dedicated to enhancing the dart-playing experience by collaborating with leading dart manufacturers to bring you the best deals and discounts. As passionate enthusiasts ourselves, we understand the importance of quality equipment without breaking the bank. Explore our website to discover a curated collection of discount codes, ensuring that you can elevate your dart game without compromising on affordability.<br/><br/>We're always on the lookout for exciting partnerships and sponsorship opportunities! If you have a collaboration in mind or would like to discuss potential opportunities, we'd love to hear from you. Reach out to us to start a conversation. Let's explore the possibilities together!


                </p>
                <br/>
                <Link to="mailto:contact@dartsdatacentre.com">Contact Us</Link>
            </div>
            <div className="col">
                <img src={process.env.PUBLIC_URL + '/assets/promo.jpg'}  />
            </div>
        </div>
    </div>
    );
}

export default Affiliates;