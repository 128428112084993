import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link } from "react-router-dom";
import WatchDarts from "../components/watch_darts";

function WatchPage() {
    return (

    <WatchDarts/>

    );
}

export default WatchPage;