import React, { useState, useEffect } from 'react';

const PastEventsAPI = () => {
  const [data, setData] = useState([]);
  const [expandedCard, setExpandedCard] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = localStorage.getItem('apiData');
        {/* Current Data */}
        const storedTimestamp = localStorage.getItem('apiTimestamp');
        {/* Current Time */}
        const currentTimestamp = new Date().getTime();
        {/* Check if Data needs updating & stored */}
        if (storedData && storedTimestamp && currentTimestamp - parseInt(storedTimestamp, 10) < 24 * 60 * 60 * 1000) {
          setData(JSON.parse(storedData));
        } else {
          const response = await fetch(process.env.REACT_APP_DB_API_KEY_PREV_EVENTS);
          const result = await response.json();
          setData(result.events);
          {/* Store API Response */}
          localStorage.setItem('apiData', JSON.stringify(result.events));
          {/* Store Timestamp API Response was saved in Local Storage */}
          localStorage.setItem('apiTimestamp', currentTimestamp.toString());
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const toggleAccordion = (index) => {
    setExpandedCard((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="events">
      <h1>PDC | Last 15 Events</h1>
      {data.map((event, index) => (
        <div onClick={() => toggleAccordion(index)} className={`event_card ${index === expandedCard ? 'expanded' : ''}`} key={event.idEvent}>
          <h3>{event.strEvent}</h3>
          <h6>{event.strVenue} | {event.strCountry}</h6>
          <h5>{event.dateEvent}</h5>
          <p>{event.strDescriptionEN}</p>
          <a>View Results</a>
          {index === expandedCard && (
            <div className="accordion_content">
              <br/>
              <span dangerouslySetInnerHTML={{ __html: event.strResult.replace(/\r\n/g, '<br />') }} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default PastEventsAPI;