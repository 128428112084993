import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link } from "react-router-dom";
import PrivacyPolicy from '../components/privacypolicy';

function PrivacyPolicyPage() {
    return (
        <PrivacyPolicy/>
    );
}

export default PrivacyPolicyPage;