import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Partners from "../components/partners";

function LibraryPage() {
    return (

    <Partners/>

    );
}

export default LibraryPage;