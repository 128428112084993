import React from 'react';

function Promo() {
    return (
    <div className="promo">
        <div className="details">
            <img src={process.env.PUBLIC_URL + '/assets/affiliate.png'} />
            <p>We're looking for partners! - Contact us now!</p>
        </div>
        <div className="cta">
            <a href="mailto:contact@dartsdatacentre.com">Get in touch!</a>
        </div>
    </div>
    );
}

export default Promo;