import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link } from "react-router-dom";
import NextEventsAPI from "../components/nextevents";
import PastEventsAPI from "../components/pastevents";

function LibraryPage() {
    return (
    <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="events_wrapper">
            {/* Past 15 Events & Results */}
            <PastEventsAPI />
            {/* Next 15 Events & Results */}
            <NextEventsAPI />
        </div>
    </>
    );
}

export default LibraryPage;