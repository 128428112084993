const watchData = [
    { id: 1, 
        label: "Gerwyn Price vs Michael Smith | World Darts Championship | Full Match", 
        link: "https://www.youtube.com/watch?v=KXI_xXfYZxg",
     },
     { id: 2, 
        label: "Match of the tournament?! 🤯 | Michael van Gerwen vs Mensur Suljović | Full Match", 
        link: "https://www.youtube.com/watch?v=5P9H1zMgLVQ",
     },
     { id: 3, 
        label: "BEST PROTOUR FINAL EVER?! | Littler v Searle | Players Championship 1 Final", 
        link: "https://www.youtube.com/watch?v=A-GsI9IhXK4",
     },
     { id: 4, 
        label: "Jonny Clayton vs Michael Smith 💥| Thrilling last-16 showdown | Full Match", 
        link: "https://www.youtube.com/watch?v=u8xmSKw3QqM",
     },
     { id: 5, 
        label: "RECORD-BREAKING AVERAGES! Van Barneveld v Van Gerwen | 2017 World Darts Championship", 
        link: "https://www.youtube.com/watch?v=d3DGDHipWbc",
     },
     { id: 6, 
        label: "Remarkable comeback! | Peter Wright vs Callan Rydz | World Darts Championship | Full Match", 
        link: "https://www.youtube.com/watch?v=0lWvXtgape4",
     },
     { id: 7, 
        label: "A WINTER GARDENS CLASSIC! | Taylor v Anderson | 2014 World Matchplay", 
        link: "https://www.youtube.com/watch?v=PKV1FNTw_6Q",
     },
     { id: 8, 
        label: "Gary Anderson vs Luke Humphries | | PDC World Darts Championship | Full Match", 
        link: "https://www.youtube.com/watch?v=feNmyHcidVk",
     },
     { id: 9, 
        label: "Phil Taylor's last Matchplay title! 2017 World Matchplay Final - Full Match", 
        link: "https://www.youtube.com/watch?v=Udo6tjrC9zc",
     },
     { id: 10, 
        label: "The most MEMORABLE moments from the World Darts Championship 2022! 🤩", 
        link: "https://www.youtube.com/watch?v=IVMKy9AZBCY",
     },
     { id: 11, 
        label: "Every PDC World Championship nine-darter 9️⃣🎯", 
        link: "https://www.youtube.com/watch?v=ImH0-Ui5nIg",
     },
     { id: 12, 
        label: "Darts' Biggest Bust-Ups | Part 1", 
        link: "https://www.youtube.com/watch?v=S9AZCr40fyk",
     },
     { id: 13, 
        label: "Stephen Bunting vs Ross Smith | Full Match", 
        link: "https://www.youtube.com/watch?v=wIDhY6VALVw",
     },
     { id: 14, 
        label: "The Match That Made HISTORY | Fallon Sherrock v Ted Evetts| Full Match", 
        link: "https://www.youtube.com/watch?v=z9Cfj5Q2MQE",
     },
     { id: 15, 
        label: "Gary Anderson defies spirited fightback from Rob Cross | PDC World Darts Championship | Full Match", 
        link: "https://www.youtube.com/watch?v=HG2uIjAjPkQ",
     },
     { id: 16, 
        label: "BEST DARTS MATCH EVER | Phil Taylor v Adrian Lewis, 2013 Grand Slam of Darts", 
        link: "https://www.youtube.com/watch?v=BB8GrKtZwaE",
     },
     { id: 17, 
        label: "Bully Boy masterclass 💥 | Michael Smith vs William O'Connor | Full Match", 
        link: "https://www.youtube.com/watch?v=_DhhTCCjTYw",
     },
     { id: 18, 
        label: "THE MOST DISCUSSED MATCH OF 2018 | Price v Anderson | 2018 Grand Slam of Darts Final", 
        link: "https://www.youtube.com/watch?v=u4v0iymS6MY",
     },
     { id: 19, 
        label: "ALL WRIGHT ON THE NIGHT | Final | 2019/20 World Darts Championship", 
        link: "https://www.youtube.com/watch?v=eHD6fBuN7i8",
     },
     { id: 20, 
        label: "Match of the tournament?!? | Rob Cross vs Daryl Gurney | 2022 World Darts Championship | Full Match", 
        link: "https://www.youtube.com/watch?v=CC34PXNH5eA",
     },
     { id: 21, 
        label: "DOMINANCE FROM MVG! Van Gerwen v Smith | 2018 Premier League Final", 
        link: "https://www.youtube.com/watch?v=IMcDBRsy7Es",
     },
     { id: 22, 
        label: "LUKE LITTLER'S FIRST TV TITLE! Littler v Van Gerwen | 2024 Bahrain Darts Masters FULL FINAL", 
        link: "https://www.youtube.com/watch?v=CwQev1bVo-k",
     },
  ];
  
  export default watchData;