import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import articleData from '../data/articledata';

function RelatedContent() {
    {/* Set Filter Category */}
    const [filterCategory, setFilterCategory] = useState('');
    {/* Get Category Value */}
    useEffect(() => {
        const h4Value = document.querySelector('.info h4')?.textContent;
        setFilterCategory(h4Value || '');
    }, []); 
    {/* Filter Articles */}
    const filteredArticles = articleData.filter(item => item.category === filterCategory);
    return (
        <div className="related_content">
            <h2>Related Content</h2>
            <h4>Scroll to see more!</h4>
            <div className="related_content_wrapper">
                {/* Iterate Articles */}
                {filteredArticles.map(item => (
                <div
                    style={{
                    width: "95%",
                    backgroundColor: "#efefef",
                    margin: "10px 20px 0px 0px"
                    }}
                    key={item.id}
                    className="article_card">
                    <h5>{item.title}</h5>
                    <h6 style={{ color: "#efefef" }}>{item.category}</h6>
                    <p>{item.summary}</p>
                    <Link to={item.link}>Read More</Link>
                </div>
                ))}
            </div>
        </div>
    );
}

export default RelatedContent;