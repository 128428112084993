const articleData = [
    { id: 1, 
        title: "Getting Started: Darts 101", 
        author: "Darts Data Centre",
        category: "Getting Started",
        summary: "Dive into the world of darts with our comprehensive guide, 'Darts 101,' featured on Darts Data Centre. This beginner-friendly article provides essential tips, rules, and techniques to kickstart your dart-playing journey. From selecting the right equipment to mastering the basic throws, discover the key elements for a successful start in the game.",
        link: "/gettingstarted",
        featured: true,
     },
     { id: 2, 
        title: "Getting Started: Dartboards", 
        author: "Darts Data Centre",
        category: "Dartboard",
        summary: "Embark on your dartboard journey with 'Getting Started with Dartboards,' an informative guide by Darts Data Centre. Explore essential tips on choosing the right board, proper setup, and maintenance. Elevate your darting experience as we unravel the secrets to selecting the perfect dartboard for your skill level and preferences.",
        link: "/article2_gettingstarteddartboards",
        featured: true,
     },
     { id: 3, 
        title: "10 Dart Brands You Need To Know About", 
        author: "Darts Data Centre",
        category: "Darts",
        summary: "Discover the top 10 must-know darts brands with our exclusive guide on Darts Data Centre. From renowned manufacturers to emerging innovators, explore the diverse world of quality dart equipment. Stay ahead in the game as we unveil the brands that define precision, durability, and style in the dynamic realm of darts.",
        link: "/article3_tendartsbrands",
        featured: true,
     },
     { id: 4, 
        title: "Throw Like a Pro", 
        author: "Darts Data Centre",
        category: "Darts",
        summary: "Elevate your dart game with 'Throw Like a Pro,' a definitive guide by Darts Data Centre. Uncover expert techniques, precision tips, and practice routines to enhance your throwing skills. From grip mastery to strategic positioning, learn the secrets that propel you towards professional-level accuracy and consistency on the dartboard.",
        link: "/article4_throwlikeapro",
        featured: true,
     },
     { id: 5, 
        title: "Get Your Own Custom Darts With Cuesoul!", 
        author: "Darts Data Centre",
        category: "Darts",
        summary: "Personalize your darting experience with 'Get Your Own Custom Darts with Cuesoul' on Darts Data Centre. Explore the innovative world of custom darts, courtesy of Cuesoul. From design options to performance enhancements, discover how tailoring your equipment elevates both style and gameplay, making every throw uniquely yours.",
        link: "/article5_cuesoulcustoms",
        featured: true,
     },
     { id: 6, 
        title: "Which Dartboard Should You Buy?", 
        author: "Darts Data Centre",
        category: "Dartboard",
        summary: "Navigate the world of dartboard options with our guide on Darts Data Centre. 'Which Dartboard Should You Buy?' offers insights into selecting the perfect board for your game. Explore factors like material, durability, and features to make an informed choice, ensuring an optimal and enjoyable darting experience.",
        link: "/article6_whichdartboardtobuy",
        featured: true,
     },
     { id: 7, 
      title: "Dartboard Lights and Surrounds - Where to Start", 
      author: "Darts Data Centre",
      category: "Dartboard",
      summary: "Illuminate your darting space and enhance your setup with 'Dartboard Lights and Surrounds: Where Should You Start?' on Darts Data Centre. Explore the essential considerations for selecting the right lighting and surrounds to elevate your game. Discover the perfect balance between ambiance, visibility, and style for an immersive experience.",
      link: "/article7_dartboardlightsurround",
      featured: true,
   },
   { id: 8, 
      title: "Getting Started with Swiss Points", 
      author: "Darts Data Centre",
      category: "Darts",
      summary: "Embark on a precision journey with 'Getting Started with Swiss Points' on Darts Data Centre. Dive into the innovative world of customizable darts tips by Swiss Points from Target. Learn the benefits, installation process, and how these cutting-edge points can elevate your dart game, offering a personalized and adaptable playing experience.",
      link: "/article8_gettingstartedswisspoints",
      featured: true,
   },
  ];
  
  export default articleData;