import React, { useState } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import communityResources from '../data/communityresources';

function Community() {
    {/* Search Term Variable */}
    const [searchTerm, setSearchTerm] = useState('');
    {/* Handle Search Term */}
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    {/* Filter Articles by Search Term */}
    const filteredResults = communityResources.filter(item =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.summary.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="library">
            <h1>Connect with other players!</h1>
            <p>Explore the Darts Data Centre community hub, featuring a curated collection of darts communities, apps, platforms, and social media groups. Connect with fellow enthusiasts, whether you're seeking opponents for a friendly match, discussing strategies, or simply socializing with passionate players. Discover the perfect space to enhance your darting experience and join a global network of like-minded individuals</p>
            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search resources..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {/* Iterate Articles by Search Term */}
            <div className="community_articles">
                {filteredResults.map(item => (
                    <div key={item.id} className="community_card">
                        <h5>{item.label}</h5>
                        <h6>{item.type}</h6>
                        <p>{item.summary}</p>
                        <Link to={item.link}>Visit</Link>
                    </div>
                ))}
            </div>
        </div>
        </>
        
    );
}

export default Community;