import React from 'react';

function TermsOfUse() {
    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
            <div className="privacy_wrapper">
                <h2>Darts Data Centre Terms of Use</h2>
                <p><strong>Effective Date:</strong> 1st February 2024</p>
                <p>Welcome to Darts Data Centre ("we," "our," or "us"). By accessing or using our website (the "Site"), you agree to
                    comply with and be bound by the following Terms of Use. If you do not agree to these terms, please refrain from
                    using our services.</p>
                <h3>1. Use of the Site</h3>
                <p>You agree to use the Site for lawful purposes and in a manner consistent with all applicable laws and regulations.
                    You also agree not to:</p><br/>
                    <p>Violate or attempt to violate the security of the Site</p>
                    <p>Interfere with the proper functioning of the Site</p>
                    <p>Attempt to gain unauthorized access to any portion of the Site</p>
                    <p>Engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Site</p>
                <h3>2. Intellectual Property</h3>
                <p>All content on the Site, including text, graphics, logos, images, and software, is the property of Darts Data Centre
                    and is protected by intellectual property laws. You may not use, reproduce, distribute, or create derivative works
                    based on this content without our explicit permission.</p>
                <h3>3. User Contributions</h3>
                <p>By submitting content to the Site, you grant Darts Data Centre a non-exclusive, royalty-free, perpetual, and
                    worldwide license to use, modify, display, and distribute the submitted content for the purpose of operating and
                    improving the Site.</p>
                <h3>4. Disclaimer of Warranties</h3>
                <p>The information provided on the Site is for general informational purposes only. We make no representations or
                    warranties of any kind, express or implied, regarding the accuracy, completeness, reliability, or suitability of the
                    information on the Site.</p>
                <h3>5. Limitation of Liability</h3>
                <p>Darts Data Centre and its affiliates, directors, officers, employees, agents, and licensors shall not be liable for
                    any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether
                    incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses resulting from:</p><br/>
                    <p>Your access to or use of or inability to access or use the Site</p>
                    <p>Any unauthorized access to or use of our servers and/or any personal information stored therein</p>
                <h3>6. Changes to Terms of Use</h3>
                <p>We reserve the right to update or modify these Terms of Use at any time without prior notice. Changes will be
                    effective immediately upon posting on the Site. Your continued use of the Site after the posting of changes
                    constitutes your acceptance of such changes.</p>
                <h3>7. Contact Us</h3>
                <p>If you have any questions, concerns, or requests regarding these Terms of Use, please contact us at privacy@dartsdatacentre.com</p>
                <p>Thank you for using Darts Data Centre in accordance with these Terms of Use.</p>
            </div>
        </>
    );
}

export default TermsOfUse;