import React, { useState } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import articleData from '../data/articledata';

function Library() {
    {/* Search Term Variable */}
    const [searchTerm, setSearchTerm] = useState('');
    {/* Handle Search Term */}
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    {/* Filter Articles by Search Term */}
    const filteredArticles = articleData.filter(item =>
        item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.summary.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="library">
            <h1>Library</h1>
            <p>Welcome to Darts Data Centre, your ultimate partner in the world of darts! We are thrilled to have you join our community of dart enthusiasts, where passion meets precision. At Darts Data Centre, we've curated a space that goes beyond being just a website—it's your go-to destination for a wealth of knowledge, the latest news, and exclusive discount codes from top dart brands.<br/><br/> Whether you're a seasoned player seeking to refine your skills or a beginner taking your first steps into the exciting world of darts, we've got you covered. Explore our articles, stay updated with the latest news, and enjoy unbeatable deals with our exclusive discount codes. Get ready to elevate your dart game with Darts Data Centre – where every throw counts, and every visit brings you one step closer to darting excellence!</p>
            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search articles..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {/* Iterate Articles by Search Term */}
            <div className="library_articles">
                {filteredArticles.map(item => (
                    <div key={item.id} className="article_card">
                        <h5>{item.title}</h5>
                        <h6>{item.category}</h6>
                        <p>{item.summary}</p>
                        <Link to={item.link}>Read More</Link>
                    </div>
                ))}
            </div>
        </div>
        </>
        
    );
}

export default Library;