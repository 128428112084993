import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout";
import Home from "./pages/home";
import Library from "./pages/library";
import GettingStarted from "./pages/gettingstarted";
import Darts from "./pages/darts";
import Dartboards from "./pages/dartboards";
import ConnectWithPlayers from "./pages/connectwithplayers";
import PrivacyPolicy from "./pages/privacypolicy";
import TermsOfUse from "./pages/termsofuse";
import Events from "./pages/events";
import Watch from "./pages/watch";
import Partners from "./pages/partners";
import NoPage from "./pages/NoPage";
import Article2 from "./pages/articles/article2_gettingstarteddartboards";
import Article3 from "./pages/articles/article3_tendartsbrands";
import Article4 from "./pages/articles/article4_throwlikeapro";
import Article5 from "./pages/articles/article5_cuesoulcustoms";
import Article6 from "./pages/articles/article6_whichdartboardtobuy";
import Article7 from "./pages/articles/article7_dartboardlightsurround";
import Article8 from "./pages/articles/article8_gettingstartedswisspoints";

export default function DDCRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="library" element={<Library />} />
          <Route path="gettingstarted" element={<GettingStarted />} />
          <Route path="darts" element={<Darts />} />
          <Route path="dartboards" element={<Dartboards />} />
          <Route path="connectwithplayers" element={<ConnectWithPlayers />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="termsofuse" element={<TermsOfUse />} />
          <Route path="events" element={<Events />} />
          <Route path="watch" element={<Watch />} />
          <Route path="partners" element={<Partners />} />
          <Route path="*" element={<NoPage />} />
          <Route path="article2_gettingstarteddartboards" element={<Article2 />} />
          <Route path="article3_tendartsbrands" element={<Article3 />} />
          <Route path="article4_throwlikeapro" element={<Article4 />} />
          <Route path="article5_cuesoulcustoms" element={<Article5 />} />
          <Route path="article6_whichdartboardtobuy" element={<Article6 />} />
          <Route path="article7_dartboardlightsurround" element={<Article7 />} />
          <Route path="article8_gettingstartedswisspoints" element={<Article8 />} />
        </Route>
      </Routes>
    </BrowserRouter>
    
  );
}

ReactDOM.render(<DDCRoutes />, document.getElementById("root"));