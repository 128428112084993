import React, { useState, useEffect } from 'react';
import RelatedContent from '../../components/relatedcontent';


function Article () {

    return (
            <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            {/* Article Details & Author */}
            <div className="author_wrapper">
                <img src={process.env.PUBLIC_URL + '/assets/author.jpg'}/>
                <div className="info">
                    <h2>10 Dart Brands You Need To Know About</h2>
                    <h4>Darts</h4>
                    <p>Darts is a game that has transcended generations, captivating players of all ages around the world. The right set of darts can make a significant difference in your performance and overall enjoyment of the game. In this blog post, we'll explore the top 10 darts brands that every enthusiast should be familiar with. From rich histories to innovative designs, these brands have left a lasting impact on the world of darts.</p>
                </div>
            </div>
            {/* Related Content Component*/}
            <RelatedContent/> 
            {/* Article Content*/}
                <div className="content">
                <header>
                    <h1>10 Dart Brands You Need to Know About</h1>
                </header>
                <nav>
                    <h2>Table of Contents</h2>
                    <ul>
                    <li><a href="#unicorn">Unicorn</a></li>
                    <li><a href="#harrows">Harrows</a></li>
                    <li><a href="#winmau">Winmau</a></li>
                    <li><a href="#target">Target</a></li>
                    <li><a href="#red-dragon">Red Dragon</a></li>
                    <li><a href="#viper">Viper</a></li>
                    <li><a href="#cosmo">Cosmo Darts</a></li>
                    <li><a href="#shot">Shot Darts</a></li>
                    <li><a href="#designa">Designa</a></li>
                    <li><a href="#cuesoul">CUESOUL</a></li>
                    </ul>
                </nav>
                <article>
                    <p>If you're into darts, having the right set of darts can make all the difference in your game. Here are ten dart brands you need to know about:</p>
                    <section id="unicorn">
                    <h2>1. Unicorn</h2>
                    <p>Unicorn is a renowned name in the world of darts, known for their high-quality products and innovative designs. Established in 1937, Unicorn has a long history of producing top-notch darts for players of all levels. Their prices vary depending on the materials and craftsmanship, but generally, they are considered mid to high-range in terms of pricing. Pros include their durable construction and precise balance, while some cons might include higher price points compared to budget brands.</p>
                    </section>
                    <section id="harrows">
                    <h2>2. Harrows</h2>
                    <p>Harrows offers a wide range of darts suitable for players of all levels, from beginners to professionals. Founded in 1973, Harrows has been a staple in the darting community for decades. Their dart sets come in various styles and price points, making them accessible to a broad audience. Pros of Harrows darts include their durability and consistent performance, while some players might find certain models to be on the heavier side, which could be a con for those who prefer lighter darts.</p>
                    </section>
                    <section id="winmau">
                    <h2>3. Winmau</h2>
                    <p>Winmau is a leading dart brand, famous for their precision-engineered darts and dartboards. Founded in 1945, Winmau has a rich history of providing top-quality products to the darting community. Their darts range from affordable options for beginners to high-end sets for professionals. Pros of Winmau darts include their excellent balance and grip, while some cons may include higher price points for their premium models.</p>
                    </section>
                    <section id="target">
                    <h2>4. Target</h2>
                    <p>Target produces top-quality darts and accessories, trusted by players worldwide. Established in 2006, Target has quickly risen to prominence in the darting industry. They offer a diverse range of darts, catering to players of all skill levels and preferences. Pros of Target darts include their innovative designs and superior performance, while some cons might include higher prices compared to budget-friendly brands.</p>
                    </section>
                    <section id="red-dragon">
                    <h2>5. Red Dragon</h2>
                    <p>Red Dragon offers a wide selection of darts in various styles and weights to suit every player's preference. Founded in 1977, Red Dragon has become synonymous with quality and innovation in the darting world. Their darts are known for their unique designs and high-performance materials. Pros of Red Dragon darts include their affordability and variety, while some players may find certain models to be less durable compared to premium brands.</p>
                    </section>
                    <section id="viper">
                    <h2>6. Viper</h2>
                    <p>Viper is known for their durable and affordable darts, perfect for beginners and casual players. Established in 1980, Viper has been providing quality dart products at budget-friendly prices. Their darts come in various styles and weights, making them suitable for players of all ages and skill levels. Pros of Viper darts include their affordability and durability, while some cons might include less variety compared to higher-end brands.</p>
                    </section>
                    <section id="cosmo">
                    <h2>7. Cosmo Darts</h2>
                    <p>Cosmo Darts specializes in soft-tip darts, providing high-performance options for electronic dartboards. Founded in 1970, Cosmo Darts has been at the forefront of soft-tip dart technology. Their darts are known for their precision and accuracy, making them popular among competitive players. Pros of Cosmo Darts include their high-quality construction and precise balance, while some cons might include higher prices compared to traditional steel-tip darts.</p>
                    </section>
                    <section id="shot">
                    <h2>8. Shot Darts</h2>
                    <p>Shot Darts crafts premium quality darts with a focus on innovation and design. Established in 2007, Shot Darts has quickly gained recognition for their unique and stylish dart designs. Their darts are favored by professional players for their performance and aesthetics. Pros of Shot Darts include their innovative designs and superior craftsmanship, while some cons might include higher price points compared to budget brands.</p>
                    </section>
                    <section id="designa">
                    <h2>9. Designa</h2>
                    <p>Designa offers affordable yet high-quality darts and accessories, making them a favorite among budget-conscious players. Founded in 1995, Designa has been providing players with reliable dart products at competitive prices. Their darts come in various weights and styles, catering to a wide range of preferences. Pros of Designa darts include their affordability and durability, while some cons might include fewer customization options compared to premium brands.</p>
                    </section>
                    <section id="cuesoul">
                    <h2>10. CUESOUL</h2>
                    <p>CUESOUL provides stylish and functional darts and dart accessories, catering to players who value both performance and aesthetics. Established in 2008, CUESOUL has quickly become known for their innovative dart designs and high-quality materials. Their darts are favored by players who appreciate both style and performance. Pros of CUESOUL darts include their stylish designs and excellent performance, while some cons might include higher price points for their premium models.</p>
                    </section>
                </article>
            </div>  
        </>  
    );
}

export default Article ;