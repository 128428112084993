import React, { useState, useEffect } from 'react';

const NextEventsAPI = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = localStorage.getItem('apiDataNext');
        {/* Current Data */}
        const storedTimestamp = localStorage.getItem('apiTimestampNext');
        {/* Current Time */}
        const currentTimestamp = new Date().getTime();
        {/* Check if Data needs updating & stored */}
        if (storedData && storedTimestamp && currentTimestamp - parseInt(storedTimestamp, 10) < 24 * 60 * 60 * 1000) {
          setData(JSON.parse(storedData));
        } else {
          const response = await fetch(process.env.REACT_APP_DB_API_KEY_NEXT_EVENTS);
          const result = await response.json();
          setData(result.events);
          {/* Store API Response */}
          localStorage.setItem('apiDataNext', JSON.stringify(result.events));
          {/* Store Timestamp API Response was saved in Local Storage */}
          localStorage.setItem('apiTimestampNext', currentTimestamp.toString());
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="events">
      <h1>PDC | Upcoming Events</h1>
      {data.map((event, index) => (
        <div className="event_card" key={event.idEvent}>
          <h3>{event.strEvent}</h3>
          <h6>{event.strVenue} | {event.strCountry}</h6>
          <h5>{event.dateEvent}</h5>
          <p>{event.strDescriptionEN}</p>
        </div>
      ))}
    </div>
  );
};

export default NextEventsAPI;