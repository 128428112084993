import React, { useState } from 'react';
import ReactDOM from "react-dom";
import DartsLibrary from '../components/dartslibrary';

function DartsLibraryPage() {
    return (
        <DartsLibrary/>
    );
}

export default DartsLibraryPage;