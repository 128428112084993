import React from 'react';

function PrivacyPolicy() {
    return (
        <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className="privacy_wrapper">
                <h2>Darts Data Centre Privacy Policy</h2>
                <p><strong>Effective Date:</strong> February 1st 2024</p>
                <p>Thank you for visiting Darts Data Centre ("we," "our," or "us"). This Privacy Policy is designed to help you
                    understand how we collect, use, and safeguard your personal information. By accessing or using our website (the
                    "Site"), you acknowledge and agree to the terms of this Privacy Policy.</p>
                <h3>Information We Collect</h3>
                <p><strong>1. Personal Information:</strong> We may collect personal information that you voluntarily provide, such as
                    your name, email address, and any other information you submit through our website, including comments, inquiries,
                    or registration forms.</p>
                <p><strong>2. Non-Personal Information:</strong> We may automatically collect non-personal information when you visit
                    our website, such as your IP address, browser type, and access times. This information is used for analytical
                    purposes and to enhance the user experience.</p>
                <h3>How We Use Your Information</h3>
                <p>We use the information we collect for various purposes, including but not limited to:</p><br/>
                    <p>1. Providing and improving our services</p>
                    <p>2. Responding to your inquiries and requests</p>
                    <p>3. Sending promotional materials and updates</p>
                    <p>4. Analyzing user behavior to enhance the user experience</p>
                    <p>5. Displaying relevant advertisements</p>
                <h3>Cookies and Tracking Technologies</h3>
                <p>We use cookies and similar tracking technologies to improve your experience on our website. Cookies are small text
                    files stored on your device that help us analyze usage patterns, provide customized content, and deliver targeted
                    advertisements. You can modify your browser settings to disable cookies, but please note that certain features of
                    the Site may not function properly if cookies are disabled.</p>
                <h3>Third-Party Advertisers and Affiliates</h3>
                <p>Darts Data Centre may engage third-party advertisers and affiliates to display advertisements on our website. These
                    third parties may use cookies and similar technologies to collect information about your activities on the Site and
                    other websites to provide you with targeted advertisements. Our Privacy Policy does not apply to the activities of
                    these third parties, and we encourage you to review their privacy policies.</p>
                <h3>Security</h3>
                <p>We take reasonable measures to protect the confidentiality and security of your personal information. However, no
                    data transmission or storage system is completely secure, and we cannot guarantee the security of your information.
                </p>
                <h3>Your Choices</h3>
                    <p>You have the right to opt-out of receiving promotional communications from us by following the unsubscribe
                    instructions provided in the emails. You can also contact us directly to update or delete your personal information.
                </p>
                <h3>Changes to Privacy Policy</h3>
                <p>We reserve the right to update or modify this Privacy Policy at any time without prior notice. Changes will be
                    effective immediately upon posting on the Site. We encourage you to review this Privacy Policy periodically.</p>
                <h3>Contact Us</h3>
                <p>If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at privacy@dartsdatacentre.com</p>
                <p>By using Darts Data Centre, you agree to the terms outlined in this Privacy Policy.</p>
            </div>
        </>
    );
}

export default PrivacyPolicy;