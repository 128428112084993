import React, { useState, useEffect } from 'react';
import RelatedContent from '../components/relatedcontent';


function GettingStartedPage () {

    return (
            <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            {/* Article Details & Author */}
            <div className="author_wrapper">
                <img src={process.env.PUBLIC_URL + '/assets/author.jpg'}/>
                <div className="info">
                    <h2>Getting Started: Darts 101</h2>
                    <h4>Getting Started</h4>
                    <p>Welcome to the Getting Started section at Darts Data Centre – Your Launchpad to Dart Excellence! 🚀 Dive into the fundamentals of playing darts and unravel the intricacies of Darts. Our comprehensive guide covers everything from the basics of dartboard setup and throwing techniques to strategic gameplay and essential rules. Whether you're a complete beginner or looking to refine your skills, "Getting Started" is your go-to resource for mastering the ins and outs of playing darts. Elevate your game and start to build a solid foundation</p>
                </div>
            </div>
            {/* Related Content Component*/}
            <RelatedContent/> 
            {/* Article Content*/}
                <div className="content">
                    <h2>Getting Started: Darts 101</h2>
                    <h3>1. Understand the Basics:</h3>
                        <p>Dartboard Setup: The standard dartboard is divided into 20 numbered sections, with a bullseye in the center. Each
                        section is further divided into single, double, and triple segments.</p>
                        <p>Scoring: Players score points by hitting specific segments on the dartboard. The outer ring is worth double the
                        number, and the inner ring (known as the triple) is worth triple the number. The bullseye is divided into the outer
                        bull (25 points) and the inner bull (50 points).</p>
                    <h3>2. Set Up Your Dartboard:</h3>
                        <p>Height and Distance: The center of the bullseye should be 5 feet 8 inches (173 cm) from the floor. The throwing
                        distance, or "oche," is 7 feet 9.25 inches (237 cm) from the face of the board.</p>
                        <p>Mounting: Hang the dartboard securely on a wall, ensuring that it's level and doesn't wobble. The bullseye should be
                        at eye level when standing at the oche.</p>
                    <h3>3. Choose Your Darts:</h3>
                        <p>Weight: Darts typically weigh between 18 and 26 grams. Choose a weight that feels comfortable for you.</p>
                        <p>Grip: Experiment with different grips to find what works best. Common grips include the pencil grip, front-loaded
                        grip, and rear-loaded grip.</p>
                    <h3>4. Learn the Throw:</h3>
                        <p>Stance: Stand with your feet shoulder-width apart. Keep your body steady, and face the dartboard with your dominant
                        foot forward.</p>
                        <p>Grip: Hold the dart with a relaxed grip, using your thumb and forefinger to control the dart's release.</p>
                        <p>Aim: Focus on a specific target on the dartboard, and aim with your dominant eye.</p>
                        <p>Throw: Use a smooth, controlled motion. Don't force the throw; let the dart glide from your fingers.</p>
                    <h3>5. Playing a Game:</h3>
                        <p>501: This is a common game where players start with a score of 501 and aim to reach zero by subtracting their scores
                        with each throw.</p>
                        <p>Cricket: In this game, players take turns aiming at numbers 15 to 20 and the bullseye. The goal is to "close" these
                        numbers and the bullseye while preventing your opponent from doing the same.</p>
                        <p>Around the Clock: Players take turns hitting each number on the dartboard in numerical order.</p>
                    <h3>6. Etiquette and Safety:</h3>
                        <p>Wait Your Turn: Allow each player to complete their turn before retrieving your darts.</p>
                        <p>Respect: Avoid distracting or disturbing other players during their throw.</p>
                        <p>Safe Play: Make sure the area around the dartboard is clear to prevent accidents.</p>
                    <h3>7. Practice Regularly:</h3>
                        <p>Consistency: Practice is crucial for improving your accuracy and consistency.</p>
                        <p>Variety: Try different games and techniques to keep things interesting and develop various skills.</p>
                    <h3>8. Tips for Better Accuracy:</h3>
                        <p>Focus on Follow-Through: A smooth follow-through is crucial for accuracy. Ensure your throwing arm extends naturally
                        after releasing the dart.</p>
                        <p>Eye Coordination: Keep your eye on the target and develop hand-eye coordination. Consistent aim will improve with
                        practice.</p>
                        <p>Find Your Rhythm: Develop a comfortable throwing rhythm. Experiment with the pace of your throws to find what feels
                        natural and effective for you.</p>
                    <h3>9. Strategies for Scoring:</h3>
                        <p>Start with the High Numbers: In games like 501, focus on hitting the triple segments to score higher points quickly
                        and bring your score down faster.</p>
                        <p>Closing Numbers in Cricket: When playing Cricket, prioritize closing numbers before scoring points. Once a number is
                        closed, you'll score points for hitting it while your opponent cannot.</p>
                        <p>Bullseye Strategy: In games like 501, consider aiming for the bullseye to score higher points and quickly reduce your
                        total score.</p>
                    <h3>10. Mental Game:</h3>
                        <p>Stay Calm Under Pressure: Dart games can get competitive. Maintain composure, especially in critical moments. Take a
                        breath before each throw.</p>
                        <p>Visualize Success: Before your throw, visualize the dart hitting your desired target. Positive visualization can
                        improve focus and accuracy.</p>
                        <p>Learn from Mistakes: Don't get discouraged by misses. Use them as learning opportunities to understand and correct
                        your technique.</p>
                    <h3>11. Advanced Techniques:</h3>
                        <p>Use the Dartboard Grid: Break down the dartboard into smaller sections mentally. This can help you target specific
                        areas more precisely.</p>
                        <p>Experiment with Angles: Adjust the angle of your throw to find the most effective trajectory for your darts. Some
                        players prefer a straight throw, while others find success with a slight arc.</p>
                        <p>Master the Double-In and Double-Out: In games like 501, where you must finish on a double, practice hitting doubles
                        consistently. This skill is crucial for closing out games efficiently.</p>
                    <h3>12. Equipment Maintenance:</h3>
                        <p>Sharpness of Darts: Regularly check and sharpen the tips of your darts. Sharp tips stick better to the dartboard.</p>
                        <p>Rotate Flights and Shafts: Rotate and replace flights and shafts periodically to ensure consistent flight patterns
                        and stability.</p>
                        <p>Balance Check: Make sure your darts are properly balanced. Imbalances can affect accuracy.</p>
                    <h3>13. Record and Analyze:</h3>
                        <p>Keep a Darting Journal: Record your scores, strategies, and observations. Analyzing your performance can reveal
                        patterns and areas for improvement.</p>
                        <p>Watch Professional Matches: Observe professional players to pick up techniques and strategies. Many tournaments are
                        available online.</p>
                        <p>Remember, darts is a game that rewards both skill and strategy. The more you practice and experiment, the more you'll
                        develop your unique playing style. Enjoy the journey of improvement!</p>
                </div>
        </>  
    );
}

export default GettingStartedPage ;