import React, { useState } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import articleData from '../data/articledata';

function DartboardLibrary() {
    {/* Search Term Variable */}
    const [searchTerm, setSearchTerm] = useState('');
    {/* Handle Search Term */}
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    {/* Filter Articles by Search Term & Page Category */}
    const filteredArticles = articleData.filter(
        (item) =>
          (item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.summary.toLowerCase().includes(searchTerm.toLowerCase())) &&
          item.category.toLowerCase() === 'dartboard'
      );
    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="library">
            <h1>Dartboard Article Library</h1>
            <p>Welcome to the Dartboards Section at Darts Data Centre – Your Definitive Resource for Mastering Dartboard Knowledge! 🎯 Explore the intricacies of dartboards, from top-notch brands and sturdy stands to stylish surrounds, illuminating lights, and essential accessories.<br/><br/> Whether you're seeking guidance on the perfect dartboard setup or aiming to enhance your playing environment, our Dartboards Section is your go-to destination.</p>
            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search articles..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {/* Iterate Articles by Search Term */}
            <div className="library_articles">
                {filteredArticles.map(item => (
                    <div key={item.id} className="article_card">
                        <h5>{item.title}</h5>
                        <h6>{item.category}</h6>
                        <p>{item.summary}</p>
                        <Link to={item.link}>Read More</Link>
                    </div>
                ))}
            </div>
        </div>
        </>
    );
}

export default DartboardLibrary;