import React, { useState } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import partnerData from '../data/partnerdata';

function Partners() {
    {/* Search Term Variable */}
    const [searchTerm, setSearchTerm] = useState('');
    {/* Handle Search Term */}
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    {/* Filter Articles by Search Term */}
    const filteredResults = partnerData.filter(item =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.summary.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="library">
            <h1>DDC | Partners</h1>
            <p>Your go-to destination for exclusive deals on top dart brands! At Darts Data Centre, we are dedicated to enhancing the dart-playing experience by collaborating and partnering up with leading names within the industry!</p>
            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search partners..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {/* Iterate Articles by Search Term */}
            <div className="community_articles">
                {filteredResults.map(item => (
                    <div key={item.id} className="community_card">
                        <h5>{item.label}</h5>
                        <h6>{item.country}</h6>
                        <p>{item.summary}</p>
                        <Link to={item.link}>Download</Link>
                    </div>
                ))}
            </div>
        </div>
        </>
        
    );
}

export default Partners;