import React, { useState, useEffect } from 'react';
import RelatedContent from '../../components/relatedcontent';


function Article () {

    return (
            <>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            {/* Article Details & Author */}
            <div className="author_wrapper">
                <img src={process.env.PUBLIC_URL + '/assets/author.jpg'}/>
                <div className="info">
                    <h2>Getting Started: Dartboards</h2>
                    <h4>Dartboard</h4>
                    <p>Dartboards, a quintessential component of the timeless game of darts, have stood the test of time, enchanting players across generations globally. The choice of the right dartboard can elevate your darting experience, influencing your accuracy and the sheer enjoyment derived from the game. Within this library, we delve into the realm of dartboards, unveiling the top 10 dartboard brands that every enthusiast should acquaint themselves with. With a blend of fascinating histories and cutting-edge designs, these brands have made an indelible mark on the expansive world of dart gaming.</p>
                </div>
            </div>
            {/* Related Content Component*/}
            <RelatedContent/> 
            {/* Article Content*/}
                <div className="content">
                <header>
    <h1>Getting Started: Dartboards</h1>
  </header>
  <nav>
    <h2>Table of Contents</h2>
    <ul>
      <li><a href="#introduction">Introduction</a></li>
      <li><a href="#history">History of Dartboards</a></li>
      <li><a href="#types">Types of Dartboards</a></li>
      <li><a href="#materials">Materials Used</a></li>
      <li><a href="#setup">Setting Up Your Dartboard</a></li>
      <li><a href="#maintenance">Maintenance Tips</a></li>
      <li><a href="#popularbrands">Popular Dartboard Brands</a></li>
      <li><a href="#choosing">Choosing the Right Dartboard</a></li>
      <li><a href="#wheretobuy">Where to Buy Dartboards</a></li>
      <li><a href="#conclusion">Conclusion</a></li>
    </ul>
  </nav>
  <article>
    <section id="introduction">
      <h2>Introduction</h2>
      <p>Whether you're a seasoned dart player or a novice looking to set up your first home game, understanding the essentials of dartboards is crucial. In this guide, we'll cover everything you need to know about dartboards, from their fascinating history to the various types available in the market. Get ready to embark on a journey to becoming a dartboard connoisseur and elevate your darting experience.</p>
    </section>
    <section id="history">
      <h2>History of Dartboards</h2>
      <p>The history of dartboards can be traced back to the late 19th century in England. Initially, dart games were played on tree trunks or wooden surfaces. As the game gained popularity, the demand for a dedicated target grew. In the early 20th century, the standard dartboard we know today, with numbered segments and a bullseye, was patented. Over the years, materials and manufacturing techniques have evolved, giving rise to a variety of dartboard options to suit different preferences and playing styles.</p>
    </section>
    <section id="types">
      <h2>Types of Dartboards</h2>
      <p>1. **Bristle Dartboards:** The most common and widely used dartboards are made of sisal fibers. Sisal provides a self-healing surface, meaning the holes created by dart tips close up over time, extending the dartboard's lifespan.</p>
      <p>2. **Electronic Dartboards:** Modern technology has brought us electronic dartboards that automatically calculate scores and offer a variety of games. These are ideal for players who enjoy features like sound effects and multiplayer options.</p>
      <p>3. **Magnetic Dartboards:** Designed for safe and family-friendly play, magnetic dartboards use magnetic darts that adhere to the board without causing damage. These are suitable for children and casual players.</p>
    </section>
    <section id="materials">
      <h2>Materials Used</h2>
      <p>1. **Sisal:** Bristle dartboards are typically made of sisal fibers. Sisal provides a dense and durable surface that can withstand repeated dart throws, ensuring a longer lifespan.</p>
      <p>2. **Cork:** Some older dartboards and cheaper alternatives may use cork as the playing surface. While cork is softer, it may not have the same durability as sisal.</p>
      <p>3. **Plastic:** Electronic and magnetic dartboards often use plastic materials. While not as traditional as sisal or cork, plastic boards offer unique features and are suitable for specific preferences.</p>
    </section>
    <section id="setup">
      <h2>Setting Up Your Dartboard</h2>
      <p>1. **Height:** The standard height for mounting a dartboard is 5 feet 8 inches from the center of the bullseye to the floor.</p>
      <p>2. **Distance:** The throwing distance, or the oche, should be 7 feet 9.25 inches from the face of the board. Measure this distance horizontally from the front of the board to the oche line.</p>
      <p>3. **Lighting:** Ensure proper lighting to avoid shadows on the dartboard. A well-lit environment enhances visibility and reduces eye strain during play.</p>
    </section>
    <section id="maintenance">
      <h2>Maintenance Tips</h2>
      <p>1. **Rotate the Board:** Regularly rotate the dartboard to distribute wear evenly across the surface.</p>
      <p>2. **Remove Dart Tips:** When not in use, remove the darts from the board to prevent unnecessary pressure on specific areas.</p>
      <p>3. **Clean the Surface:** Wipe the dartboard surface with a dry or slightly damp cloth to remove any dust or dirt.</p>
    </section>
    <section id="popularbrands">
      <h2>Popular Dartboard Brands</h2>
      <p>1. **Winmau:** Known for high-quality bristle dartboards, Winmau is a trusted brand among professional players.</p>
      <p>2. **Unicorn:** Unicorn offers a range of dartboards, including electronic options, catering to players of all levels.</p>
      <p>3. **Arachnid:** A leading brand in electronic dartboards, Arachnid provides innovative and feature-rich options for enthusiasts.</p>
    </section>
    <section id="choosing">
      <h2>Choosing the Right Dartboard</h2>
      <p>Consider the following factors when selecting a dartboard:</p>
      <ul>
        <li>**Skill Level:** Different dartboards cater to various skill levels, so choose one that suits your expertise.</li>
        <li>**Budget:** Determine your budget, as dartboards come in a range of prices depending on features and materials.</li>
        <li>**Game Preferences:** If you enjoy electronic features and a variety of games, an electronic dartboard might be the right choice.</li>
      </ul>
    </section>
    <section id="wheretobuy">
      <h2>Where to Buy Dartboards</h2>
      <p>Explore these options to purchase your dartboard:</p>
      <ul>
        <li>**Specialty Stores:** Dart specialty stores offer a wide selection of dartboards, providing expert advice on the best choice for your needs.</li>
        <li>**Online Retailers:** Platforms like Amazon, Dart Brokers, and A-Z Darts provide a variety of dartboards with user reviews for guidance.</li>
        <li>**Local Sporting Goods Stores:** Visit your local sporting goods stores to find dartboards suitable for casual and recreational play.</li>
      </ul>
    </section>
    <section id="conclusion">
      <h2>Conclusion</h2>
      <p>With the right knowledge, choosing and maintaining a dartboard can enhance your darting experience significantly. Whether you prefer the classic feel of bristle boards, the convenience of electronic options, or family-friendly magnetic boards, the diverse world of dartboards has something for everyone. Now equipped with essential information, you're ready to set up your own darting haven and enjoy countless hours of precision and fun.</p>
    </section>
  </article>
            </div>  
        </>  
    );
}

export default Article ;