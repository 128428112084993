import React from "react";
import { BrowserRouter, Route, Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
        <div className="col">
            <div className="footerimgs">
            <img src={process.env.PUBLIC_URL + '/assets/logo.png'}/> <img src={process.env.PUBLIC_URL + '/assets/AI.png'}/> <img src={process.env.PUBLIC_URL + '/assets/reactlogo.png'} />
            </div>
            <p>Darts Data Centre</p>
            <p>Based in United Kingdom</p>
        </div>
        {/* Footer Sitemap */}
        <div className="col">
            <h5>Sitemap</h5>
            <Link to="/">Home</Link>
            <Link to="/library">Library</Link>
            <Link to="/gettingstarted">Getting Started</Link>
            <Link to="/events">Events & Results</Link>
            <Link to="/watch">Watch Darts</Link>
            <Link to="/connectwithplayers">Connect with other Players!</Link>
        </div>
        {/* Footer Privacy Links */}
        <div className="col">
            <h5>Site Privacy</h5>
            <Link to="/privacypolicy">Privacy Policy</Link>
            <Link to="/termsofuse">Terms of Use</Link>
        </div>
        {/* Footer Contact Links */}
        <div className="col">
            <h5>Contact Us</h5>
            <a href="mailto:contact@dartsdatacentre.com">Email Us</a>
        </div>
    </div>
  );
}

export default Footer;