import React from "react";
import {Outlet} from "react-router-dom";
import Navbar from "../components/navbar";
import CookieConsent from "react-cookie-consent";
import Footer from "../components/footer";

const Layout = () => {
  return (
    <>
      {/* Navigation Bar: Desktop & Mobile */}
      <Navbar />
      {/* Page Content */}
      <Outlet />
      {/* Cookie Consent */}
      <CookieConsent buttonStyle={{ backgroundColor: "#bb0a0a", borderRadius: "5px", color: "#efefef" }}>This website uses cookies to enhance the user experience.</CookieConsent>
      {/* Footer: Desktop & Mobile */}
      <Footer />
    </>
  );
};

export default Layout;