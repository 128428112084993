import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link } from "react-router-dom";
import TermsOfUse from '../components/termsofuse';

function TermsOfUsePage() {
    return (
        <TermsOfUse/>
    );
}

export default TermsOfUsePage;