import React from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import Community from '../components/community';

function ConnectPage() {
    return (
        <Community />
    );
}

export default ConnectPage;