const communityResources = [
    { id: 1, 
        label: "r/Darts - Reddit", 
        type: "Social Media",
        summary: "The Reddit community r/darts is a platform for enthusiasts and players of the game of darts to connect, share insights, discuss strategies, and exchange information related to the sport. Members engage in conversations about equipment, tournaments, technique improvement, and contribute to a supportive community fostering a shared passion for darts.",
        link: "https://www.reddit.com/r/Darts/",
     },
     { id: 2, 
      label: "Target Dart Counter", 
      type: "Platform/App",
      summary: "DartCounter is the online darts platform for keeping track of all your darts scores. Play x01 games, Cricket, Bob's 27 and several other training games. Your scores will be announced by MasterCaller Ray Martin!",
      link: "https://dartcounter.net/en/about",
   },
   { id: 3, 
      label: "Russ Bray Darts Scorer", 
      type: "Platform/App",
      summary: "Russ Bray referees at all major Sky TV Professional Darts Corporation (PDC) tournaments including the World Championships. He is widely considered to be the best darts referee in the world. In the app Russ referees your darts match in full including speaking your name, throws, total left, and shot-outs with his silky vocal tones exactly as he does on TV.",
      link: "https://apps.apple.com/gb/app/russ-bray-darts-scorer/id377453562",
   },
   { id: 4, 
      label: "All Things Darts - Facebook", 
      type: "Social Media",
      summary: "All Things Darts is a vibrant Facebook community uniting dart enthusiasts worldwide. Members share a common love for the sport, engaging in discussions about equipment, tournaments, strategies, and fostering a supportive environment. From beginners to seasoned players, the group provides a platform for connecting, learning, and celebrating the diverse aspects of the darts community.",
      link: "https://www.facebook.com/share/MrPU3gXwHn3GujDK/",
   },
   { id: 5, 
      label: "Darts Advice, Tips and Discussion - Facebook", 
      type: "Social Media",
      summary: "Darts Advice, Tips, and Discussion on Facebook is a community dedicated to the exchange of knowledge and experiences related to the game of darts. Members actively participate in discussions offering advice, sharing tips, and engaging in conversations about techniques, equipment, and strategies. The group serves as a valuable resource for players seeking guidance and improvement in their dart-playing skills.",
      link: "https://www.facebook.com/share/xX3XknpVvVKBVNJw/",
   },
   { id: 6, 
      label: "Webcam Darts Association", 
      type: "Platform/App",
      summary: "The WDA offers you the complete webcam darts experience absolutely free. All you require is your dartboard, darts and a webcam with a microphone. The WDA will provide the rest. Playing a real game of darts against anyone in the world has never been easier and more accessible than it is right now. Our simple sign up process and huge community will have you playing a real game of darts against players from all around the world within minutes.",
      link: "https://www.webcamdarts.com/",
   },
   { id: 7, 
      label: "LiDarts", 
      type: "Platform/App",
      summary: "Play darts against real opponents. With a webcam or without. Or train against the computer. No costs, no ads, no trackers.",
      link: "https://lidarts.org/",
   },
   { id: 8, 
      label: "Nakka Darts", 
      type: "Platform/App",
      summary: "This web service allows you to keep score when playing 01 games of darts. You can connect with fellow darts players and keep score/record of your games!",
      link: "https://n01darts.com/n01/",
   },
  ];
  
  export default communityResources;