import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { BrowserRouter, Route, Link } from "react-router-dom";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import './heroswiper.css';

import { Autoplay, Pagination } from 'swiper';

export default function HeroSwiper() {

  {/* Pagination Labels */}
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      let labels = ['01&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Darts Data Centre', '02&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Getting Started', '03&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Events & Results', '04&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Watch Darts'];
      return '<span class="' + className + '">' + (labels[index])+ '</span>';
    },
  };

  return (
    <>
    <div className="hero">
      {/* Hero Swiper */}
      <Swiper
        pagination={pagination}
        modules={[Autoplay, Pagination]}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="heroSwiper"
      >
        {/* Swiper Slides*/}
        <SwiperSlide className='Darts Data Centre'>
          <h2>Your Partner in Darts</h2>
          <p>Welcome to Darts Data Centre, your ultimate partner in the world of darts! We are thrilled to have you join our community of dart enthusiasts, where passion meets precision. At Darts Data Centre, we've curated a space that goes beyond being just a website—it's your go-to destination for a wealth of knowledge, the latest news, and exclusive discount codes from top dart brands. Whether you're a seasoned player seeking to refine your skills or a beginner taking your first steps into the exciting world of darts, we've got you covered. Explore our articles, stay updated with the latest news, and enjoy unbeatable deals with our exclusive discount codes. Get ready to elevate your dart game with Darts Data Centre – where every throw counts, and every visit brings you one step closer to darting excellence!</p>
          <Link className="heroButton" to="/library">View Library</Link>
        </SwiperSlide>
        <SwiperSlide>
          <h2>Getting Started</h2>
          <p>Welcome to the Getting Started section at Darts Data Centre – Your Launchpad to Dart Excellence! 🚀 Dive into the fundamentals of playing darts and unravel the intricacies of Darts. Our comprehensive guide covers everything from the basics of dartboard setup and throwing techniques to strategic gameplay and essential rules. Whether you're a complete beginner or looking to refine your skills, "Getting Started" is your go-to resource for mastering the ins and outs of playing darts. Elevate your game and start to build a solid foundation - click read more to start your journey...</p>
          <Link className="heroButton" to="/gettingstarted">Read More</Link>
        </SwiperSlide>
        <SwiperSlide>
          <h2>Events & Results</h2>
          <p>Our Darts Events Hub is your go-to destination for everything related to upcoming darting events and recent results. Stay ahead of the game by keeping track of upcoming tournaments, exhibitions, and competitions. From local leagues to international championships, we curate a comprehensive list of events so you never miss a throw. But that's not all! We also provide timely updates on recent results, ensuring you're always in the loop with the latest outcomes and scores. Whether you're curious about who clinched the title in the latest championship or want to see how your favorite players performed, our results section has got you covered.</p>
          <Link className="heroButton" to="/events">View</Link>
        </SwiperSlide>
        <SwiperSlide>
          <h2>Watch Darts!</h2>
          <p>Welcome to the ultimate hub for dart enthusiasts! Whether you're a seasoned pro or just discovering the joy of throwing those tiny, pointy projectiles, we've got something special for you. Dive into our extensive collection of full darts matches, where the tension is palpable, and every bullseye is a triumph. If you're in the mood for a quick dose of darting excitement, check out our carefully curated darts compilations. Witness the jaw-dropping moments, incredible comebacks, and some of the most skillful throws that make darts a thrilling sport. It's the perfect way to catch up on the highlights without missing a beat.</p>
          <Link className="heroButton" to="/watch">Watch Now</Link>
        </SwiperSlide>
      </Swiper>
    </div>
    </>
  );
}