import ReactDOM from "react-dom";
import { BrowserRouter, Route, Link } from "react-router-dom";
import Library from "../components/library";

function LibraryPage() {
    return (

    <Library/>

    );
}

export default LibraryPage;