import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';

function Navbar() {
  const [isLibraryHovered, setLibraryHovered] = useState(false);
  const [isSubMenuHovered, setSubMenuHovered] = useState(false);

  return (
    <div className="centre_wrapper">
      <div className="navbar">
        {/* Navbar Logo */}
        <img src={process.env.PUBLIC_URL + '/assets/logo.png'} alt="ddc logo"/>
        {/* Desktop Navigation Links */}
        <div className="navlinks">
          <Link to="/">Home</Link>
          <Link to="/gettingstarted">Getting Started</Link>
          <Link
            onMouseEnter={() => setLibraryHovered(true)}
            onMouseLeave={() => setLibraryHovered(false)}
            to="/library"
          >
            Library
          </Link>
          {isLibraryHovered || isSubMenuHovered ? (
            <div
              className="sub_menu"
              onMouseEnter={() => setSubMenuHovered(true)}
              onMouseLeave={() => setSubMenuHovered(false)}
            >
              <Link to="/library">Full Library</Link>
              <Link to="/darts">Darts Library</Link>
              <Link to="/dartboards">Dartboard Library</Link>
              {/* Add more sub-menu items as needed */}
            </div>
          ) : null}
          <Link to="/events">Events & Results</Link>
          <Link to="/watch">Watch Darts</Link>
          {/* Partners Page 
          <Link to="/partners">Partners</Link>
          */}
          
          <Link to="/connectwithplayers">Connect!</Link>
        </div>
        {/* Mobile Menu */}
        <Menu className="mobileMenu" menuButton={<MenuButton className="mobileMenuBtn"><img src={process.env.PUBLIC_URL + '/assets/menu.png'} alt="grid menu icon"/></MenuButton>} transition>
            <MenuItem className="menuItem"><Link to="/">Home</Link></MenuItem>
            <MenuItem className="menuItem"><Link to="/gettingstarted">Getting Started</Link></MenuItem>
            <MenuItem className="menuItem"><Link to="/library">Library</Link></MenuItem>
            <MenuItem className="menuItem"><Link to="/events">Events & Results</Link></MenuItem>
            <MenuItem className="menuItem"><Link to="/watch">Watch Darts</Link></MenuItem>
            <MenuItem className="menuItem"><Link to="/connectwithplayers">Connect!</Link></MenuItem>
            <MenuItem className="menuItem"><MenuButton className="closeBtn">Close Menu</MenuButton></MenuItem>
        </Menu>
      </div>
    </div>
  );
}

export default Navbar;