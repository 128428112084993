
import React, { useState } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import watchData from '../data/watchdata';

function WatchDarts() {
    {/* Search Term Variable */}
    const [searchTerm, setSearchTerm] = useState('');
    {/* Handle Search Term */}
    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    {/* Filter Articles by Search Term */}
    const filteredResults = watchData.filter(item =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return (
        <>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <div className="library">
            <h1>Darts Data Centre | Watch Darts!</h1>
            <p>Welcome to the ultimate hub for dart enthusiasts! Whether you're a seasoned pro or just discovering the joy of throwing those tiny, pointy projectiles, we've got something special for you. Dive into our extensive collection of full darts matches, where the tension is palpable, and every bullseye is a triumph.<br/><br/>If you're in the mood for a quick dose of darting excitement, check out our carefully curated darts compilations. Witness the jaw-dropping moments, incredible comebacks, and some of the most skillful throws that make darts a thrilling sport. It's the perfect way to catch up on the highlights without missing a beat.<br/><br/>But it's not all serious competition here—we believe in the lighter side of darts too! Our funny darts moments section is dedicated to the hilarious side of the game. From unexpected blunders to lighthearted banter between players, these moments will have you laughing and appreciating the lighter side of the dartboard.</p>
            {/* Search Bar */}
            <input
                type="text"
                placeholder="Search videos..."
                value={searchTerm}
                onChange={handleSearchChange}
            />
            {/* Iterate Articles by Search Term */}
            <div className="community_articles">
                {filteredResults.map(item => (
                    <div key={item.id} className="community_card">
                        <h5>{item.label}</h5>
                        <br/>
                        <Link to={item.link}>Watch</Link>
                    </div>
                ))}
            </div>
        </div>
        </>
        
    );
}

export default WatchDarts;

