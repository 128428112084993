import React from 'react';
import articleData from '../data/articledata';
import { BrowserRouter, Route, Link } from "react-router-dom";

function FeaturedArticles() {
    return (
    <div className="wrapper">
        <h1>Featured | Explore</h1>
        <p>Your hub for invaluable resources that cater to both beginners and seasoned enthusiasts alike. Dive into a wealth of knowledge with our carefully curated articles designed to support beginners in their darting journey and empower enthusiasts to elevate their game to the next level. Whether you're just starting out or looking to refine your skills, our featured articles provide expert insights, tips, and strategies to enhance your dart-playing experience. </p>
        <div className="featured">
            {/* Iterate Featured Articles */}
            {articleData.map(item => (
                <div key={item.id} className="article_card">
                    <h5>{item.title}</h5>
                    <h6>{item.category}</h6>
                    <p>{item.summary}</p>
                    <Link to={item.link}>Read More</Link>
                </div>
            ))}
        </div>
    </div>
    );
}

export default FeaturedArticles;